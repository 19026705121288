import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BaseInput from '@/components/BaseInput';
import BaseFileUpload from '@/components/BaseFileUpload';
import './styles/helper-classes.scss';
import './styles/typography.scss';
import './styles/grid.scss';
import './styles/base.scss';
import './styles/vars.scss';

const app = createApp(App);

app
  .component('BaseInput', BaseInput)
  .component('BaseFileUpload', BaseFileUpload)
  .use(store).use(router).mount('#app')
