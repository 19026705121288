import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    requestSentSuccess: false,
    language: 'en',
    text: {
      registration: {
        en: {
          title: `Registration form`,
          subtitle: `Dear High School Students,
          <br />  
          <br />  
          We are thrilled to invite you to participate in our upcoming festival Murals of Youth, as part of our vibrant festival celebrating creativity and expression through street art!`,
          submissionEnd: `Submissions ends on 30 June 2024`,
          steps: {
            title: `STEPS`,
            list: [
              `Launching the competition invitations (18 march) Submission of sketches (scaled simulations), portfolios, registration details (18 march – 30 june 2024)`,
              `Selection of participating students for the FINAL PHASE (conducted by a jury of 4 members, artists, teachers – July 2024)`,
              `Announcement of participants for the event in Cluj-Napoca (July 2024)`,
              `Creation of artworks during the event in Cluj-Napoca Award ceremony during the event`
            ]
          },
          eligibility: {
            title: `ELIGIBILITY REQUIREMENTS`,
            list: [
              `The competition is open to high school students (Romanian + international) – grades IX – XII.`,
              `Minors must be accompanied by a teacher/an adult during the competition.`
            ]
          },
          execution: {
            title: `EXECUTION / LOGISTICS`,
            list: [
              `Participants in the FINAL PHASE will bear the travel costs.`,
              `Accommodation for students and guiding teachers/parents (as applicable/depending on age) will be provided at student dormitories.`,
              `The artworks will be created on OSB panels prepared by the organizers.`,
              `Working materials will be provided by the organizers.`,
            ]
          },
          instaDetails: `For any other information contact on our Instagram page
          <a href="https://www.instagram.com/muralsofyouth/">Murals of Youth Instagram page</a>`,
          proposalUploadDetails: `
              Please submit a draft for the project you plan to execute. Create a draft that closely resembles the final piece, ensuring that the proportions of the panels are accurately maintained.
              <br>
              <br>
              Make sure your proposal is at least 30 x 15 cm (3543 x 1772 px), 300 dpi, and you upload your best shot!
          `,
          proposalButton: `Upload your best proposal`,
          portfolioButton: `Upload your portfolio`,
          teachersSection: {
            title: `For the participants under 18`,
            details: `For students under the age of 18 who wish to participate, we kindly request that you are accompanied by a teacher or a legal guardian. This ensures not only your safety but also provides guidance and support throughout the event.`,
          }
        },
        ro: {
          title: `Formular de inregistrare`
        }
      }
    }
  },
  getters: {
    text: (state, getter) => state.text.registration[state.language],
  },
  mutations: {
    SET_STATE (state, {key, value}) {
      state[key] = value;
    }
  },
  actions: {
    async submit ({ commit, state }, payload) {
      try {
        await axios.post('/api/form', payload)
        commit('SET_STATE', {key: 'requestSentSuccess', value: true})
      } catch (err) {
        console.log(err)
      }
    }
  },
  modules: {
  }
})
